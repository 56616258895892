/* eslint-disable react/no-array-index-key */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-danger */
import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrevNext from "../components/PrevNext"
import AppWrapper from "../views/AppWrapper"
import { ContentFull } from "../elements/Content"
import Hero from "../views/Hero"
import MarkdownContainer from "../components/MarkdownContainer"
import renderMarkdown from "../helpers/renderMarkdown"

import { H1Light, PLight, WrapTextBlock } from "../elements/Text"

const ImageList = styled.div`
  ${tw`flex flex-wrap justify-center items-center lg:flex-no-wrap`};
`

const Image = styled(Img)``
const ImageWrapper = styled.div`
  ${Image} {
    ${tw`m-2`};

    width: 320px;

    @media only screen and (min-width: 901px) {
      max-height: 320px;
      width: calc(320px * ${props => props.aspectRatio});
    }
  }
`

const Section = ({ children, ...props }) => {
  const { style } = props

  return (
    <ContentFull style={style}>
      <MarkdownContainer>{children}</MarkdownContainer>
    </ContentFull>
  )
}

export default function Template({ pageContext: { prev, next }, data }) {
  const { markdownRemark } = data
  const { frontmatter, htmlAst } = markdownRemark
  const images = data.images.edges
  const seoDescription = frontmatter.subtitles
    ? frontmatter.subtitles.join(" ")
    : frontmatter.title
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        keywords={frontmatter.keywords}
        description={seoDescription}
      />
      <AppWrapper>
        <Hero isDark style={{ zIndex: 20 }}>
          <H1Light>{frontmatter.title}</H1Light>
          <PLight>
            {frontmatter.subtitles
              ? frontmatter.subtitles.map((subtitle, i) => (
                  <WrapTextBlock key={i}>{subtitle}</WrapTextBlock>
                ))
              : frontmatter.subtitles}
          </PLight>
        </Hero>
        <Section condensed>{renderMarkdown(htmlAst)}</Section>
        {images.length > 1 && (
          <Section style={tw`bg-grey`}>
            <ImageList>
              {images.map((image, i) => {
                const fluidImage = image.node.childImageSharp.fluid

                return (
                  <ImageWrapper
                    key={`img-${i}`}
                    aspectRatio={fluidImage.aspectRatio}
                  >
                    <Image
                      alt={image.node.name}
                      key={fluidImage.src}
                      fluid={fluidImage}
                      imgStyle={{ objectFit: "contain" }}
                    />
                  </ImageWrapper>
                )
              })}
            </ImageList>
          </Section>
        )}
        <Section style={tw`bg-grey-light px-0`}>
          <PrevNext prev={prev} next={next} />
        </Section>
      </AppWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $absolutePathRegex: String!) {
    images: allFile(
      filter: {
        absolutePath: { regex: $absolutePathRegex }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitles
        keywords
        hidden
      }
    }
  }
`
